import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyleWrapper=styled.div`

width:100%;
display: flex;
flex:1;
flex-direction: column;


`
const StyledDiv = styled.div`
width:100%;
display: flex;
flex-direction:column;
border-radius: 2px;
margin-bottom: 20px;
`


const H1 = styled.div`
height: auto;
color: ${({isBlue}) => isBlue ? ({theme}) => theme.blue : ({theme}) => theme.blackfont};
font-size: ${({theme}) => theme.font.size.xxm};
margin-top: ${({isTop}) => isTop ? '25px' : '10px'};
font-weight: 600;
line-height: 1.4;
margin-bottom: 30px;
`
const StyledH2= styled.h2`
font-size: ${({theme}) => theme.font.size.s};
font-family: ${({theme}) => theme.font.family.Montserrat};
color: ${({theme}) => theme.blackfont};
font-weight: 500;
margin-bottom: 2px;
`
const H3 = styled.div`
color: ${({isBlue}) => isBlue ? ({theme}) => theme.blue : ({theme}) => theme.blackfont};
font-size: ${({theme}) => theme.font.size.ss};
margin-top: ${({isTop}) => isTop ? '25px' : '0px'};
font-weight: 500;
`

const StyledP = styled.div`
font-size: ${({theme}) => theme.font.size.xs};
color: ${({theme}) => theme.blackfont};
margin-left: ${({isLeft}) => isLeft ? '60px' : '3px'};
&.styled p{
 padding: 2px 0;

 &:last-child{
   padding-bottom:5px;
   }
}
`

const StyledLinksHead=styled.div`
width: 100%;
height: auto;
display: flex;
align-items: flex-start;
flex-direction:column;
`


const Authors = ({category}) => {
    const data = useStaticQuery(graphql`
    query{
  allDatoCmsAuthor(sort: {fields: sort}) {
    nodes {
      id
      categoryauthor
      nextdata {
        ... on DatoCmsTitle {
          id
          title
        }
        ... on DatoCmsName {
          id
          name
        }
        ... on DatoCmsBookclass {
          id
          bookclass
        }
        ... on DatoCmsEducationCategory {
          id
          education
        }
      }
    }
  }
}
  `)

    return (
         <StyleWrapper>

        {data.allDatoCmsAuthor.nodes.filter(Author => Author.categoryauthor ===category).map(Author => (
            <StyledDiv key={ Author.id}>

                 <StyledLinksHead>
          {Author.nextdata.map(item => {

            const itemKey = Object.keys(item)[1];

            switch (itemKey) {
                 case 'education':
                  return <H1 isBlue key={item.id} dangerouslySetInnerHTML={{ __html: item.education }} />;
                 case 'bookclass':
                  return <StyledH2 key={item.id} dangerouslySetInnerHTML={{ __html: item.bookclass }} />;
                 case 'title':
                  return <H3 key={item.id} dangerouslySetInnerHTML={{ __html: item.title }} />;
                case 'name':
                  return <StyledP className="styled" key={item.id} dangerouslySetInnerHTML={{ __html: item.name}} />;

              default:
                return null;
            }
          })}
          </StyledLinksHead>



        </StyledDiv>
        ))}
         </StyleWrapper>


);
}
Authors.propTypes = {
  category: PropTypes.string.isRequired,

}


export default Authors;